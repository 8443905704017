import React, { Component } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import { FadeInTransition } from '../transitions/transitions';
import { PrimaryButton } from './Buttons';
import TappableCard from './TappableCard';
import { ButtonText, Text } from './Text';
import GoogleFitnessIcon from '../../images/common/google-fit.png';
import { setGoogleFitPermission } from '../../actions';
import { connect } from 'react-redux';
// import { updateHraAndGoogleFitDetailsToTata } from '../../services/tata-aig';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.7rem;
  z-index: 10;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
`;

const BackContainer = styled(TappableCard)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #16307a;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
  color: #16307a;
  font-weight: 600;
`;

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.625rem 0rem 0.625rem;
  overflow-y: scrol;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
`;

const SelectText = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #2f3e50;
  width: 100%;
  margin-left: 5%;
`;

const FitnessOptions = styled.div`
  margin-left: 7%;
  margin-right: 7%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const NextButton = styled.div`
  position: absolute;
  bottom: 2px;
  width: 100%;
  background: #303e50;
  padding: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageCard = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 0.875rem;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeading = styled.div`
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
`;
class StayActive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFitnessDevice: null,
      permissionGranted: false,
      status: false,
    };
  }

  componentDidMount() {
    const { location, dispatch } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      console.log(location.search);
      const permissionForGoogleFit = params.get('permissionGranted') === 'true';
      dispatch(setGoogleFitPermission(permissionForGoogleFit));
      // if(permissionForGoogleFit) {
      //   console.log('Permission granted');
      //   const body = {
      //     member_id: this.props.memberId,
      //     fit_integrated: permissionForGoogleFit,
      //   }
        // this.updateHraAndGoogleFitDetailsToTata(body);
      // }
      this.setState({
        permissionGranted: permissionForGoogleFit,
      });
    } else if (
      window.Android !== undefined &&
      window.Android.inFitSelectScreen
    ) {
      window.Android.inFitSelectScreen();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'inFitSelectScreen',
        })
      );
    }

    window.googleFitStatus = (value) => {
      this.setState({ status: value });
    };
  }

  // updateHraAndGoogleFitDetailsToTata = (body) => {
  //   console.log(body, 'hereeee');
  //   updateHraAndGoogleFitDetailsToTata(body)
  //     .then(() => {
  //       console.log('Hra Completed and syncing to Tata Android');
  //     })
  //     .catch((err) => {
  //     console.log(err, 'errrrr');
  //   })
  // }

  connectToFitness = () => {
    console.log('Connecting to Android SDK');
    if (window.Android !== undefined && window.Android.connectToGoogleFit) {
      window.Android.connectToGoogleFit();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'connectToGoogleFit',
        })
      );
    }
  };

  render() {
    const { selectedFitnessDevice, permissionGranted, status } = this.state;
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer>
          <HeaderContainer>
            {!this.props.googleFitPermission && (
              <BackContainer onTap={() => this.props.history.goBack()}>
                <StyledArrow />
              </BackContainer>
            )}
            <HeaderTitleContainer>
              <HeaderText
                style={
                  this.props.googleFitPermission
                    ? { marginLeft: '36px' }
                    : { margin: '0' }
                }
              >
                Stay Active
              </HeaderText>
            </HeaderTitleContainer>
          </HeaderContainer>
          <BodyContainer>
            <SelectText>Select Health App to Sync Data</SelectText>
            <FitnessOptions>
              <div className="flex flex-col items-center justify-center">
                <ImageCard
                  onClick={() => {
                    if (!status && !permissionGranted) {
                      this.setState({ selectedFitnessDevice: 'googleFit' });
                    }
                  }}
                  style={
                    !permissionGranted
                      ? {
                          border:
                            this.state.selectedFitnessDevice === 'googleFit'
                              ? '2px solid #0c2b75'
                              : 'none',
                          cursor: 'pointer',
                        }
                      : { border: 'none' }
                  }
                >
                  <img
                    src={GoogleFitnessIcon}
                    style={{ width: '64px', height: '50px' }}
                  />
                </ImageCard>
                <CardHeading>Google Fit</CardHeading>
              </div>
            </FitnessOptions>
            {permissionGranted && (
              <div
                className="mt-48px flex items-center justify-center"
                style={{ color: '#7bff9a', fontWeight: '600' }}
              >
                Google Fit Connected Successfully
              </div>
            )}
            {status && (
              <div
                className="mt-48px flex items-center justify-center"
                style={{ color: '#7bff9a', fontWeight: '600' }}
              >
                Google Fit Already Connected
              </div>
            )}
          </BodyContainer>
          {permissionGranted || status ? (
            <NextButton
              style={{ color: '#ffffff', background: '#303e4f' }}
              onClick={() => {
                if (
                  window.Android !== undefined &&
                  window.Android.googleFitConnectedAndSavedInPWA
                ) {
                  window.Android.googleFitConnectedAndSavedInPWA();
                } else if (
                  window.webkit &&
                  window.webkit.messageHandlers.visitIosView
                ) {
                  window.webkit.messageHandlers.visitIosView.postMessage(
                    JSON.stringify({
                      method: 'googleFitConnectedAndSavedInPWA',
                    })
                  );
                }
              }}
            >
              Home
            </NextButton>
          ) : (
            <NextButton
              style={
                selectedFitnessDevice
                  ? { color: '#ffffff', background: '#303e4f' }
                  : { color: '#264a9f73', background: '#f0f4ff' }
              }
              onClick={this.connectToFitness}
            >
              Next
            </NextButton>
          )}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  googleFitPermission: state.app.fitnessPermissionGranted,
  memberId: state.app.memberId,
});

export default connect(mapStateToProps)(StayActive);
