import React from 'react';
import styled from 'styled-components';
import StyledTappable from './StyledTappable';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
`;

const EnabledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #303e4f;
  box-shadow: 0px 0px 2px 2px #1c46ad80;
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px;
  margin-top: 30px;
`;

const DisabledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #26a49f;
  background: #f0f4ff;
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

let count = 0;

export default function TataButton({
  enabled,
  onTap,
  children,
  buttonText,
  width,
  pleft,
  pright,
  className,
  padding,
  tapWidth,
}) {
  return (
    <div style={{ width: tapWidth || '100%' }}>
      <StyledTappable
        onTap={() => onTap()}
        classBase="visit-button-tappable w-full"
      >
        {enabled ? (
          <EnabledButton
            style={{
              width: width || '100%',
              paddingLeft: pleft || '14px',
              paddingRight: pright || '14px',
              padding: padding,
            }}
            className={className}
          >
            {buttonText}
            {children}
          </EnabledButton>
        ) : (
          <DisabledButton
            style={{
              width: width || '80%',
              paddingLeft: pleft || '14px',
              paddingRight: pright || '14px',
              padding: padding,
            }}
            className={className}
          >
            {buttonText}
            {children}
          </DisabledButton>
        )}
      </StyledTappable>
    </div>
  );
}
