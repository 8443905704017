import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BalanceBadge } from './Fitcoin';
import TappableCard from './TappableCard';
import Tappable from 'react-tappable/lib/Tappable';

import logo from '../../images/common/visitfulllogo.png';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 15px;
`;

const HeaderLogo = styled.img`
  height: 1.875rem;
`;

const BackContainer = styled(Tappable)`
  margin-right: 3px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const closePWA = (history) => {
  if (window?.Android?.closeView) {
    window.Android.closeView(true);
  } else if (window?.webkit?.messageHandlers?.visitIosView) {
    window.webkit.messageHandlers.visitIosView.postMessage(
      JSON.stringify({
        method: 'closeView',
        tataUser: true,
      })
    );
  } else {
    history.goBack();
  }
};

const HomeHeader = ({ balance, history, showFitCoins, sponsorLogo }) => (
  <HeaderContainer>
    <div className="flex">
      <BackContainer onTap={() => closePWA(history)}>
        <svg
          width="7"
          height="13"
          viewBox="0 0 7 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 2.35117L2.67342 6.96667L7 11.5822L5.66802 13L1.56711e-06 6.96667L5.66802 0.933336L7 2.35117Z"
            fill="#0F0B28"
          />
        </svg>
      </BackContainer>
      <HeaderLogo src={sponsorLogo} alt="TATA AIG" />
    </div>
    {/* {showFitCoins && (
      <TappableCard onTap={() => history.push('/home/rewards')}>
        <BalanceBadge balance={balance} />
      </TappableCard>
    )} */}
  </HeaderContainer>
);

HomeHeader.propTypes = {
  balance: PropTypes.string.isRequired,
  showFitCoins: PropTypes.bool.isRequired,
};

export default HomeHeader;
